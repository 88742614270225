import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { computed, effect, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, of, shareReplay, take, tap, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Logger } from '../../../@common/log/logger';
import { LocalStorageService } from '../../storage/application/local-storage.service';
import { CreateUser } from '../entities/create-user.interface';
import { PasswordUpdate } from '../entities/password-update.interface';
import { UserProfile } from '../entities/user-profile.interface';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private localStorageService = inject(LocalStorageService);
    private http = inject(HttpClient);
    private logger = inject(Logger);
    private router = inject(Router);

    public token = signal<string | null>(this.localStorageService.getAuthToken());
    public userProfile = signal<UserProfile | null>(null);
    public isAuthenticated = computed(() => {
        this.localStorageService.setAuthToken(this.token());

        if (!this.token()) {
            this.localStorageService.setUserId(null);
        }

        return !!this.token()
    });
    public isAdmin = computed(() => this.userProfile()?.user.is_staff || this.userProfile()?.user.is_superuser);

    constructor() {
        this.setupUserProfile().subscribe(profile => this.userProfile.set(profile));

        effect(() => {
            if (this.userProfile()) {
                this.localStorageService.setUserData(this.userProfile())
            }
        });
    }

    private setupUserProfile(): Observable<UserProfile | null> {
        const cachedUserProfile = this.localStorageService.getUserProfile();

        if (cachedUserProfile || !this.localStorageService.getAuthToken()) {
            return of(cachedUserProfile);
        }

        return this.http.get<UserProfile>(`${ ApiPath.auth }/user/`, { withCredentials: true })
            .pipe(
                tap(profile => this.localStorageService.setUserData(profile)),
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get user profile failed', e);
                    return of(null);
                })
            );
    }

    public updateProfile(userData: UserProfile) {
        return this.http.patch<UserProfile>(`${ ApiPath.auth }/update/`, userData, { withCredentials: true })
            .pipe(
                take(1),
                tap(profile => {
                    // Because currenlty message is being returned
                    if (profile.user) {
                        this.userProfile.set(profile)
                    } else {
                        // remove so that it is refetched
                        this.localStorageService.setUserData(null);
                    }
                }),
                catchError(e => {
                    this.logger.apiError('Update user profile failed', e);
                    return throwError(() => e);
                })
            );
    }

    public updatePassword(passwordData: PasswordUpdate): Observable<any> {
        return this.http.put<any>(`${ ApiPath.auth }/pass/`, passwordData, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Update password failed', e);
                    return throwError(() => e);
                })
            );
    }

    login(username: string, password: string) {
        return this.http.post<{ token: string, user_details: UserProfile }>(`${ ApiPath.auth }/login/`, {
            username,
            password
        })
            .pipe(
                tap(res => {
                    if (res) {
                        this.userProfile.set(res.user_details);
                        this.localStorageService.setUserId(res.user_details.user.id!.toString());
                        this.localStorageService.setAuthToken(res.token);
                        this.router.navigate([ '/' ]);
                    }

                    this.token.set(res?.token || null);
                }),
                take(1),
                catchError(e => {
                    this.logger.apiError('Login failed', e);
                    return throwError(() => e)
                })
            );
    }

    register(user: CreateUser) {
        return this.http.post<any>(`${ ApiPath.auth }/register/`, { user }).pipe(
            take(1),
            catchError(e => {
                if (e instanceof HttpErrorResponse && e.status.toString().startsWith('5')) {
                    this.logger.apiError('Register failed', e);
                }

                return throwError(() => e)
            })
        );
    }

    activateRegistration(uid: string, token: string) {
        return this.http.post<any>(`${ ApiPath.auth }/activate/`, { uid, token }, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    if (e instanceof HttpErrorResponse && e.status.toString().startsWith('5')) {
                        this.logger.apiError('Activate registration failed', e);
                    }
                    return throwError(() => e)
                })
            );
    }

    logout() {
        this.token.set(null);

        this.router.navigate([ '/' ]);
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.post<any>(`${ environment.apiUrl }/auth/reset/`, { email: email, send_email: true })
            .pipe(
                take(1),
                catchError(e => {
                    if (e instanceof HttpErrorResponse && e.status.toString().startsWith('5')) {
                        this.logger.apiError('Forgot Password failed', e);
                    }

                    return of(null);
                })
            );
    }

    resetPasswordChange(id: string, token: string, password: string) {
        return this.http.post(`${ environment.apiUrl }/auth/reset/`, {
                uid: id,
                token: token,
                new_password: password,
                send_email: false,
            },
            {
                withCredentials: true
            }
        ).pipe(
            take(1),
            catchError(e => {
                this.logger.apiError('Reset password failed', e);
                return throwError(() => e)
            })
        );
    }

    public getAnonymousUserId() {
        if (!this.localStorageService.getDeviceId()) {
            this.localStorageService.setDeviceId(this.generateUUID());
        }

        return this.localStorageService.getDeviceId()!;
    }

    private generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

}
